export const APP_ROUTES = {
  CREATE_ORDER: "/dms/create-order" /* pages/vehicle-selection.tsx */,
  CUSTOMER_DETAILS: "/dms/customer-details" /* pages/customer-details.tsx */,
  QUOTATION: "/dms/quotation" /* pages/quotation.tsx */,
  ORDER_SUMMARY: "/dms/order-summary" /* pages/order-summary.tsx */,
  LOGIN: "/auth" /* pages/login.tsx */,
  CHANGE_PASSWORD: "/dms/change-password" /* pages/login.tsx */,
  WORKQUEUE: "/dms/workqueue" /* pages/workqueue.tsx */,
  INVENTORY_MANAGEMENT:
    "/dms/inventory-management" /* pages/inventory-management.tsx */,
  SSO_SUCCESS: "/auth/success" /* pages/ssoSccess.tsx */,
}
