import { FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "@pages/login";
import { APP_ROUTES } from "../../shared/config/links";
import SsoSuccessPage from "@pages/ssoSuccess";
import { LoginValidatorComponent } from '@ntpkunity/controls-ums'

const OrderRoutes: FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const returnUri = queryParams.get('return_uri');

  return (
    <Routes>
      {returnUri && (
        <Route path={APP_ROUTES.LOGIN} element={<Login />} />
      )}
      <Route path={APP_ROUTES.SSO_SUCCESS} element={<LoginValidatorComponent><SsoSuccessPage /></LoginValidatorComponent>} />
      < Route path="*" element={< h1 style={{ textAlign: "center", paddingTop: "100px" }}> 404 not found</h1 >} />
    </Routes >
  );
};

export default OrderRoutes;
