import { FC } from "react";
import { SsoSuccess } from '@ntpkunity/controls-ums'
import { AuthLayout } from "@layout/authentication";

const SsoSuccessPage: FC = () => {
    return (
        <AuthLayout>
            <SsoSuccess />
        </AuthLayout>
    );
};

export default SsoSuccessPage;
